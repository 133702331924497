<template>
    <b-container fluid>
        <iq-card class="pt-2">
            <template v-slot:body>
                <iq-card class="mt-2" v-if="isOrgAdmin">
                    <template v-slot:body>
                        <b-row>
                            <b-col>
                                <b-row>
                                    <b-col lg="12" class="d-flex justify-content-center mb-1">
                                        <h4 class="font-weight-bolder" style="color: #08a20c;">{{ currentOrgName }}</h4>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
                <iq-card class="mt-2" v-else-if="upazilaId">
                    <template v-slot:body>
                        <b-row>
                            <b-col>
                                <b-row>
                                    <b-col lg="4" class="text-center mb-1">
                                        <div>
                                            <h4 class="font-weight-bolder" style="color: #08a20c;">{{ $t('globalTrans.district') }} : {{ getDistrictName }}</h4>
                                        </div>
                                    </b-col>
                                    <b-col lg="4" class="text-center mb-1">
                                        <div>
                                            <h4 class="font-weight-bolder" style="color: #08a20c;">{{ $t('globalTrans.upazila') }} : {{ upazilaName }}</h4>
                                        </div>
                                    </b-col>
                                    <b-col lg="4" class="text-center mb-1">
                                        <div>
                                            <h4 class="font-weight-bolder" style="color: #08a20c;">{{ $t('pump_install.office') }} : {{ getOfficeName }}</h4>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
                <b-row class="mb-4" v-else>
                    <b-col>
                        <b-row>
                            <b-col lg="12" class="d-flex justify-content-center mb-4">
                                <h4 class="font-weight-bolder">{{ $t('globalTrans.welcomeToAdminDashboard') }}</h4>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="2"></b-col>
                            <b-col lg="6">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    :label="$t('globalTrans.selectOrg')"
                                    label-for="org_id"
                                >
                                    <b-form-select
                                        plain
                                        v-model="orgId"
                                        :options="orgList"
                                        id="org_id"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option value="">{{ $t('globalTrans.select') }}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4">
                                <b-button type="button" variant="primary" @click="filterData">{{ $t('globalTrans.search')}}</b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-overlay :show="loader.scheme">
                    <b-row>
                        <b-col md="3" sm="6" class="custom-box-shadow">
                            <div class="bg-success p-3 rounded mb-3 text-center">
                                <h2 class="text-white">{{ $n(data.scheme.total) }}</h2>
                                <h5 class="text-white d-block">{{ $t('irriDashboard.totalSchemeApp')}}</h5>
                            </div>
                        </b-col>
                        <b-col md="3" sm="6" class="custom-box-shadow">
                            <div class="bg-success p-3 rounded mb-3 text-center">
                                <h2 class="text-white">{{ $n(data.scheme.approved) }}</h2>
                                <h5 class="text-white d-block">{{ $t('irriDashboard.approvedScheme')}}</h5>
                            </div>
                        </b-col>
                        <b-col md="3" sm="6" class="custom-box-shadow">
                            <div class="bg-success p-3 rounded mb-3 text-center">
                                <h2 class="text-white">{{ $n(data.scheme.rejected) }}</h2>
                                <h5 class="text-white d-block">{{ $t('irriDashboard.rejectedScheme')}}</h5>
                            </div>
                        </b-col>
                        <b-col md="3" sm="6" class="custom-box-shadow">
                            <div class="bg-success p-3 rounded mb-3 text-center">
                                <h2 class="text-white">{{ $n(data.scheme.pending) }}</h2>
                                <h5 class="text-white d-block">{{ $t('irriDashboard.pendingScheme')}}</h5>
                            </div>
                        </b-col>
                    </b-row>
                </b-overlay>
                <b-overlay :show="loader.total">
                    <b-row>
                        <b-col md="3" sm="6" class="custom-box-shadow">
                            <div class="bg-cobalt-blue p-3 rounded mb-3 text-center">
                                <h2 class="text-white">{{ $n(data.total.pumpInstalled) }}</h2>
                                <h5 class="text-white d-block">{{ $t('irriDashboard.totalInstalledPump')}}</h5>
                            </div>
                        </b-col>
                        <b-col md="3" sm="6" class="custom-box-shadow">
                            <div class="bg-cobalt-blue p-3 rounded mb-3 text-center">
                                <h2 class="text-white">{{ $n(data.total.smartCards) }}</h2>
                                <h5 class="text-white d-block">{{ $t('irriDashboard.totalSmartCard')}}</h5>
                            </div>
                        </b-col>
                        <b-col md="3" sm="6" class="custom-box-shadow">
                            <div class="bg-cobalt-blue p-3 rounded mb-3 text-center">
                                <h2 class="text-white">{{ $n(data.total.waterTests) }}</h2>
                                <h5 class="text-white d-block">{{ $t('irriDashboard.totalWaterTestingLab')}}</h5>
                            </div>
                        </b-col>
                        <b-col md="3" sm="6" class="custom-box-shadow">
                            <div class="bg-cobalt-blue p-3 rounded mb-3 text-center">
                                <h2 class="text-white">&#2547; {{ $n(data.payment) }}</h2>
                                <h5 class="text-white d-block">{{ $t('irriDashboard.payment')}}</h5>
                            </div>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
        <!-- Apex Chart -->
        <iq-card class="pt-2">
            <template v-slot:body>
                <b-row>
                    <b-col lg="6">
                        <b-overlay :show="loader.complain">
                            <h5 class="text-center">{{ $t('irriDashboard.status.complain')}}</h5>
                            <apexchart class="chart-1" type="bar" :options="complain('options')" :series="complain()"/>
                        </b-overlay>
                    </b-col>
                    <b-col lg="6">
                        <b-overlay :show="loader.scheme">
                            <h5 class="text-center">{{ $t('irriDashboard.status.scheme')}}</h5>
                            <apexchart class="chart-1" type="pie" :options="scheme('options')" :series="scheme()"></apexchart>
                        </b-overlay>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-overlay :show="loader.visitor">
            <iq-card class="pt-2">
                    <template v-slot:body>
                        <h5 class="text-center">{{ $t('irriDashboard.status.visitYearly')}}</h5>
                        <apexchart width="100%" height="300px" type="bar" :options="visitor('options')" :series="visitor()"/>
                    </template>
            </iq-card>
        </b-overlay>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { fetchScheme, fetchTotal, fetchComplain } from '../api/routes'
// import setActiveMenus from '@/Utils/sidebar-menus'

export default {
    name: 'IrrigationDashboard',
    data () {
        return {
            isOrgAdmin: false,
            authorizedURIs: [],
            currentOrg: {},
            upazilaId: 0,
            officeName: '',
            districtId: 0,
            loader: {
                scheme: false,
                total: false,
                complain: false,
                visitor: false
            },
            orgId: '',
            apiPaths: {
                scheme: fetchScheme,
                total: fetchTotal,
                complain: fetchComplain
            },
            data: null
        }
    },
    computed: {
        currentOrgName () {
            return (this.$i18n.locale === 'bn') ? this.currentOrg.text_bn : this.currentOrg.text
        },
        upazilaName () {
            let returnVal = ''
            const upazilas = this.$store.state.commonObj.upazilaList
            const upazila = upazilas.find(el => el.value === this.upazilaId)
            if (upazila) {
                returnVal = (this.$i18n.locale === 'bn') ? upazila.text_bn : upazila.text
            }
            return returnVal
        },
        getDistrictName () {
            const district = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(this.districtId))
            if (typeof district === 'undefined') {
                return ''
            } else {
                return district.text
            }
        },
        getOfficeName () {
            return (this.officeName) ? this.officeName : ''
        },
        orgList () {
            // return this.$store.state.commonObj.organizationProfileList
            // Remove the following and uncomment the above snippet if Common Service Mixin is removed from Irrigation layout
            const orgIdList = this.$store.state.orgComponentList.filter(item => item.component_id === 9)
            const orgList = this.$store.state.orgList
            return orgIdList.map(item => orgList.find(elem => elem.value === item.org_id))
        },
        schemeLabels () {
            return [
                this.$t('irriDashboard.scheme.approved'),
                this.$t('irriDashboard.scheme.pending'),
                this.$t('irriDashboard.scheme.rejected')
            ]
        },
        complainLabels () {
            return [
                this.$t('irriDashboard.complain.reviewed'),
                this.$t('irriDashboard.complain.resolved'),
                this.$t('irriDashboard.complain.completed'),
                this.$t('irriDashboard.complain.pending'),
                this.$t('irriDashboard.complain.total')
            ]
        },
        visitorLabels () {
            return [
                this.$t('months.jan'),
                this.$t('months.feb'),
                this.$t('months.mar'),
                this.$t('months.apr'),
                this.$t('months.may'),
                this.$t('months.jun'),
                this.$t('months.jul'),
                this.$t('months.aug'),
                this.$t('months.sep'),
                this.$t('months.oct'),
                this.$t('months.nov'),
                this.$t('months.dec')
            ]
        }
    },
    watch: {
        orgId (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.orgId = newVal
            }
        }
    },
    methods: {
        checkUser () {
            const user = this.$store.state.Auth.authUser
            this.isOrgAdmin = user.is_org_admin
            if (user.role_id === 0 && user.is_org_admin === 1) {
                this.orgId = user.org_id
                this.filterData()
                const org = this.$store.state.orgList.find(el => el.value === user.org_id)
                if (org) {
                    this.currentOrg = org
                }
            } else if (user.role_id === 0) {
                this.orgId = user.office_detail.org_id
                this.districtId = user.office_detail.district_id
                this.upazilaId = user.office_detail.upazilla_id
                this.officeName = (this.$i18n.locale === 'bn') ? user.office_detail.office_name_bn : user.office_detail.office_name
                this.filterData()
            } else {
                this.filterData()
            }
        },
        scheme (type = null) {
            const vm = this

            if (type === 'options') {
                return {
                    colors: ['#e69900', '#0073e6', '#660035'],
                    chart: {
                        width: 380,
                        type: 'pie'
                    },
                    labels: vm.schemeLabels,
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                }
            } else {
                return [
                    vm.data.scheme.approved,
                    vm.data.scheme.pending,
                    vm.data.scheme.rejected
                ]
            }
        },
        complain (type = null) {
            const vm = this

            if (type === 'options') {
                return {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: vm.complainLabels
                    }
                }
            } else {
                return [{
                    data: [
                        vm.data.complain.reviewed,
                        vm.data.complain.resolved,
                        vm.data.complain.complete,
                        vm.data.complain.pending,
                        vm.data.complain.total
                    ]
                }]
            }
        },
        visitor  (type = null) {
            const vm = this
            if (type === 'options') {
                return {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: vm.visitorLabels
                    }
                }
            } else {
                return [{
                    data: [400, 430, 448, 470, 400, 430, 448, 470, 540, 448, 470, 540]
                }]
            }
        },
        filterData () {
            for (const [key, value] of Object.entries(this.apiPaths)) {
                this.getData(value, key)
            }
        },
        getData (apiPath, type) {
            this.loader[type] = true
            this.tempData()
            RestApi.getData(irriSchemeServiceBaseUrl, apiPath + '?org_id=' + this.orgId + '&far_upazilla_id=' + this.upazilaId)
                .then(response => {
                this.loader[type] = false
                if (response.success) {
                    this.data[type] = Object.assign({}, this.data[type], response.data)
                }
            }).catch((error) => {
                if (error.response) {
                    this.loader[type] = false
                }
            })
        },
        tempData () {
            this.data = {
                scheme: {
                    approved: 0,
                    pending: 0,
                    rejected: 0,
                    total: 0
                },
                total: {
                    pumpInstalled: 0,
                    smartCards: 0,
                    waterTests: 0
                },
                complain: {
                    reviewed: 0,
                    resolved: 0,
                    complete: 0,
                    pending: 0,
                    total: 0
                },
                payment: 0,
                visitor: null
            }
        }
    },
    created () {
        this.tempData()
        this.checkUser()
    },
    mounted () {
        core.index()
    }
}
</script>

<style scoped lang="scss">
    .chart-1 {
        width: 380px;
        height: 100%;
    }
    .custom-box-shadow div {
        transition: box-shadow 300ms;
        cursor: pointer;
    }
    .custom-box-shadow:hover div {
        -webkit-box-shadow: 0px 5px 15px -1px rgba(61,61,61,1);
        -moz-box-shadow: 0px 5px 15px -1px rgba(61,61,61,1);
        box-shadow: 0px 5px 15px -1px rgba(61,61,61,1);
        transition-timing-function: ease-in-out;
    }
</style>
